import { useParams } from "react-router-dom";
import { MigrateOpsAwsWizard } from "./aws/MigrateOpsAwsWizard";
import { OperationRecipeRoute } from "./MigrateOpsWizardCommon";
import { MigrateOpsThinDataReportWizard } from "./thinDataReport/MigrateOpsThinDataReportWizard";
import { MigrateOpsLocalDataMigrationWizard } from "./localDataMigration/MigrateOpsLocalDataMigrationWizard";

// ======================
// MigrateOpsWizardView
// ======================

interface MigrateOpsWizardViewProps {}

export const MigrateOpsWizardView: React.FC<MigrateOpsWizardViewProps> = (p) => {
    const { recipe } = useParams();

    const recipeRoute = recipe as OperationRecipeRoute;

    if (recipeRoute === "aws") {
        return <MigrateOpsAwsWizard />;
    }
    if (recipeRoute === "source-data-assessment") {
        return <MigrateOpsThinDataReportWizard />;
    }
    // if (recipeRoute === "local-data-migration") {
    //     return <MigrateOpsLocalDataMigrationWizard />;
    // }
};
