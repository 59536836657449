import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { OperationConfig, OperationJson } from "../MigrateOpsWizardCommon";
import _ from "lodash-es";

interface ThinDataReportConfig extends OperationConfig {
    migrateops_migration_source_assessment_report: {
        include_all_systems_in_project: boolean;
        include_system_names?: Array<string>;
        exclude_system_names?: Array<string>;
        exclude_boot_volumes?: boolean;
        default_qos_level?: string;
    };
}

interface ThinDataReportJson extends OperationJson {
    config: ThinDataReportConfig;
}
export interface MigrateOpsThinDataReportWizardState {
    operationJson: ThinDataReportJson;
    setOperationJsonField: (fields: string | Array<string>, value: any) => void;
    includeSystems: boolean;
    setIncludeSystems: (value: boolean) => void;
    resetState: () => void;
}

const getIntialJson = (): ThinDataReportJson => {
    return {
        name: "Migration Source Assessment Report",
        notes: "",
        recipe: "MIGRATEOPS_MIGRATION_SOURCE_ASSESSMENT_REPORT",
        config: {
            system_id: undefined,
            integrationId: undefined,
            scheduled_start_time: undefined,
            migrateops_migration_source_assessment_report: {
                include_all_systems_in_project: undefined,
                include_system_names: [],
                exclude_system_names: [],
                exclude_boot_volumes: undefined,
                default_qos_level: undefined,
            },
        },
    };
};

export const useMigrateOpsThinDataReportWizardState = create<MigrateOpsThinDataReportWizardState>()(
    immer((set, get) => ({
        operationJson: getIntialJson(),
        setOperationJsonField: (fields: string | Array<string>, value: any) => {
            if (Array.isArray(fields)) {
                const key = fields.join(".");
                set((state) => {
                    _.set(state.operationJson, key, value);
                });
            } else {
                set((state) => {
                    state.operationJson[fields as keyof ThinDataReportJson] = value;
                });
            }
        },
        includeSystems: true,
        setIncludeSystems: (value: boolean) => {
            set((state) => {
                state.includeSystems = value;
            });
        },
        resetState: () => {
            set((state) => {
                state.operationJson = getIntialJson();
                state.includeSystems = true;
            });
        },
    }))
);
