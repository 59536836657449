import { ScreenContainer, ScreenTitleBar } from "../../../layout/ScreenCommon";
import { StepConfig } from "../../../../common/stepper/StepperHelpers";
import React from "react";
import { DynamicStepper } from "../../../../common/stepper/StepperComponents";
import {
    DefaultQosLevelStep,
    ExcludeBootVolumesStep,
    FinishUpStep,
    GetStartedStep,
    IncludeAllSystemsStep,
    SelectSystemsStep,
} from "./MigrateOpsThinDataReportWizardSteps";
import { useNavigateToMigrateOpsRecipeWizard } from "../MigrateOpsWizardCommon";
import { Button } from "@mui/material";

// ======================
// CreateThinDataReportButton
// ======================

interface CreateThinDataReportButtonProps {}

export const CreateThinDataReportButton: React.FC<CreateThinDataReportButtonProps> = (p) => {
    const goToOpWizard = useNavigateToMigrateOpsRecipeWizard();
    return (
        <Button
            variant={"contained"}
            onClick={() => {
                goToOpWizard("source-data-assessment");
            }}
        >
            {"Create Assessment"}
        </Button>
    );
};

export enum ThinDataReportWizardStepId {
    GET_STARTED = "get-started",
    INCLUDE_ALL_SYSTEMS = "include-all-systems",
    SELECT_SYSTEMS = "select-systems",
    EXCLUDE_BOOT_VOLUMES = "exclude-boot-volumes",
    DEFAULT_QOS_LEVEL = "default-qos-level",
    FINISH_UP = "finish-up",
}

// ======================
// MigrateOpsThinDataReportWizard
// ======================

interface MigrateOpsThinDataReportWizardProps {}

export const MigrateOpsThinDataReportWizard: React.FC<MigrateOpsThinDataReportWizardProps> = (p) => {
    const [currentStep, setCurrentStep] = React.useState(0);
    const getStepConfigs = (): StepConfig[] => {
        const getStartedStep: StepConfig = {
            id: ThinDataReportWizardStepId.GET_STARTED,
            label: "Getting Started",
            renderer: () => <GetStartedStep currentStep={currentStep} setCurrentStep={setCurrentStep} />,
        };

        const includeAllsystemsStep: StepConfig = {
            id: ThinDataReportWizardStepId.INCLUDE_ALL_SYSTEMS,
            label: `Include All Systems`,
            renderer: () => <IncludeAllSystemsStep currentStep={currentStep} setCurrentStep={setCurrentStep} />,
        };

        const selectSystemsStep: StepConfig = {
            id: ThinDataReportWizardStepId.SELECT_SYSTEMS,
            label: "Select Systems",
            renderer: () => <SelectSystemsStep currentStep={currentStep} setCurrentStep={setCurrentStep} />,
        };

        const excludeBootVolumesStep: StepConfig = {
            id: ThinDataReportWizardStepId.EXCLUDE_BOOT_VOLUMES,
            label: "Exclude Boot Volumes",
            renderer: () => <ExcludeBootVolumesStep currentStep={currentStep} setCurrentStep={setCurrentStep} />,
        };

        const defaultQosLevelStep: StepConfig = {
            id: ThinDataReportWizardStepId.DEFAULT_QOS_LEVEL,
            label: "Default QOS Level",
            renderer: () => <DefaultQosLevelStep setCurrentStep={setCurrentStep} currentStep={currentStep} />,
        };

        const finishUpStep: StepConfig = {
            id: ThinDataReportWizardStepId.FINISH_UP,
            label: "Finish Up",
            renderer: () => <FinishUpStep setCurrentStep={setCurrentStep} currentStep={currentStep} />,
        };

        return [getStartedStep, includeAllsystemsStep, selectSystemsStep, excludeBootVolumesStep, defaultQosLevelStep, finishUpStep];
    };

    const [stepConfigs, setStepConfigs] = React.useState<StepConfig[]>(getStepConfigs());

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"Create Source Data Assessment"} />
            <DynamicStepper
                hideStepper
                stepperProps={{
                    activeStep: currentStep,
                    stepConfigs: stepConfigs,
                    startingStep: 0,
                    totalSteps: stepConfigs.length,
                }}
                orientation={"horizontal"}
                useTransitions
            />
        </ScreenContainer>
    );
};
