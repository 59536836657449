import { generatePath, useNavigate, useParams } from "react-router-dom";
import { getProjectSubRouteTemplate, MIGRATE_OPS_SUBROUTE, PROJECT_SUBROUTE } from "../../app/AppRoutes";
import { OperationRecipeID } from "gc-web-proto/galaxycompletepb/operationpb/operation_pb";
import { useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { sanitizeObjectOfEmptyFields } from "../MigrateOpsNewOperationBuilderState";
import { Box, CardContent, Dialog, DialogContent, Link } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { DialogTopBar } from "../../core/dialog/DialogComponents";
import { CodeCard } from "../../../common/card/DarkCard";
import * as YAML from "yaml";
export interface OperationConfig {
    system_id: string;
    integrationId?: number;
    scheduled_start_time?: string;
}
export interface OperationJson {
    name: string;
    notes: string;
    recipe: string;
}

export type OperationRecipeRoute = "azure" | "aws" | "local-data-migration" | "hyperv" | "cluster" | "nutanix" | "remote-migration" | "source-data-assessment";

export const useNavigateToMigrateOpsRecipeWizard = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();

    return (recipeRoute: OperationRecipeRoute) => {
        const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATE_OPS) + `/${MIGRATE_OPS_SUBROUTE.WIZARD}/${recipeRoute}`, {
            projectId,
        });
        navigate(p);
    };
};

export const getOperationRecipeRoute = (opRecipe: OperationRecipeID): OperationRecipeRoute => {
    if (opRecipe === OperationRecipeID.MIGRATEOPS_AZURE_COMPUTE) {
        return "azure";
    }
    if (opRecipe === OperationRecipeID.MIGRATEOPS_AWS_COMPUTE) {
        return "aws";
    }
    if (opRecipe === OperationRecipeID.MIGRATEOPS_LOCAL_DATA_MIGRATION) {
        return "local-data-migration";
    }
    if (opRecipe === OperationRecipeID.MIGRATEOPS_HYPERV_COMPUTE) {
        return "hyperv";
    }
    if (opRecipe === OperationRecipeID.MIGRATEOPS_LOCAL_MULTINODE_CLUSTER_DATA_MIGRATION) {
        return "cluster";
    }
    if (opRecipe === OperationRecipeID.MIGRATEOPS_NUTANIX_COMPUTE) {
        return "nutanix";
    }
    if (opRecipe === OperationRecipeID.MIGRATEOPS_REMOTE_DATA_MIGRATION) {
        return "remote-migration";
    }
    if (opRecipe === OperationRecipeID.MIGRATEOPS_MIGRATION_SOURCE_ASSESSMENT_REPORT) {
        return "source-data-assessment";
    }
};

// ======================
// ViewCurrentYaml
// ======================

interface ViewCurrentYamlButtonProps {
    operationJson: object;
}

export const ViewCurrentYamlButton: React.FC<ViewCurrentYamlButtonProps> = (p) => {
    const { operationJson } = p;
    const dialogState = useDialogState();
    const clonedJson = JSON.parse(JSON.stringify(operationJson));
    const sanitizedJson = sanitizeObjectOfEmptyFields(clonedJson, true);
    const fullScreen = useShouldDialogFullScreen();
    return (
        <>
            <Link sx={{ color: alpha("rgb(255,255,255)", 0.3) }} variant={"subtitle2"} onClick={dialogState.open}>
                {"View MigrateOps Config"}
            </Link>
            {dialogState.isOpen && (
                <Dialog fullScreen={fullScreen} maxWidth={"md"} fullWidth open={dialogState.isOpen} onClose={dialogState.close}>
                    <DialogTopBar dialogState={dialogState} title={"MigrateOps Configuration"} />
                    <DialogContent>
                        <CodeCard sx={{ maxHeight: "calc(100vh - 250px)", overflow: "auto" }}>
                            <CardContent sx={{ height: "100%", overflow: "auto" }}>
                                <Box>
                                    <pre>
                                        {YAML.stringify(sanitizedJson, {
                                            indent: 2,
                                            defaultKeyType: "PLAIN",
                                            defaultStringType: "QUOTE_DOUBLE",
                                        })}
                                    </pre>
                                </Box>
                            </CardContent>
                        </CodeCard>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};
