// ======================
// IncludeAllSystemsStep
// ======================

import { useMigrateOpsThinDataReportWizardState } from "./MigrateOpsThinDataReportWizardState";
import { Alert, AlertTitle, Box, Button, CardContent, Checkbox, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useStepperContext } from "../../../../common/stepper/StepperState";
import { SlideAndFadeInStep, StepperNavButtons } from "../../../../common/stepper/StepperComponents";
import { SelectableCard } from "../../../../common/card/SelectableCard";
import { SyncQos } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import MinimumMode from "../../../../assets/migrationSession/minimum.png";
import ModerateMode from "../../../../assets/migrationSession/moderate.png";
import AggressiveMode from "../../../../assets/migrationSession/aggressive.png";
import RelentlessMode from "../../../../assets/migrationSession/relentless.png";
import { getSyncQosImpactLevelLabel, useLinearProgressStyle } from "../../../cmcMigration/CmcMigrationCommon";
import { useListGalaxyMigrateDeployments } from "../../../deployment/deployment_hooks";
import { useCurrentProjectID } from "../../../project/CurrentProjectState";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import { GalaxyMigrateDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { BlackTooltip } from "../../../../common/tooltip/ColorTooltip";
import { parseCpuCountsFromCpuString } from "../../../deployment/DeploymentCommon";
import { formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import { getResourceUsageColor, HostInfoDisplay } from "../../../galaxymigrate/GalaxyMigrateDeploymentsList";
import { QueryTable } from "../../../../common/table/QueryTable";
import { DeploymentQueryKeys } from "../../../../common/QueryKeys";
import * as YAML from "yaml";
import { CodeCard, DarkFlatOutlinedCard } from "../../../../common/card/DarkCard";
import { sanitizeObjectOfEmptyFields } from "../../MigrateOpsNewOperationBuilderState";
import { ClipboardButton, ClipboardText } from "../../../../common/clipboard/ClipboardComponents";
import { useNavigateToNewOpScreen, useNavigateToOpDetails } from "../../MigrateOpsCommon";
import { ViewCurrentYamlButton } from "../MigrateOpsWizardCommon";
import { ThinDataReportWizardStepId } from "./MigrateOpsThinDataReportWizard";
import { SimpleCodeEditor } from "../../../../common/editor/SimpleCodeEditor";
import { generatePath } from "react-router-dom";
import { getProjectSubRouteTemplate, PROJECT_SUBROUTE } from "../../../app/AppRoutes";
import { useCreateNewOperationFromYaml } from "../../migrate_ops_hooks";

interface MigrateOpsThinDataReportWizardStepProps {
    currentStep: number;
    setCurrentStep: (step: number) => void;
}

// ======================
// GetStartedStep
// ======================

export const GetStartedStep: React.FC<MigrateOpsThinDataReportWizardStepProps> = (p) => {
    const { currentStep, setCurrentStep } = p;
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");
    const id = ThinDataReportWizardStepId.GET_STARTED;
    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Stack direction={"column"} spacing={6}>
                <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={2}>
                    <Typography variant={"h2"}>{"Let's get started."}</Typography>
                    <Typography variant={"body1"}>
                        {"In just a few questions, we'll be able to set up the basic configuration to generate your source data assessment."}
                    </Typography>
                </Stack>
                <Box>
                    <StepperNavButtons
                        nextButtonProps={{
                            onClick: () => {
                                setSlideDirection("down");
                                setCurrentStep(stepConfigs.findIndex((s) => s.id === ThinDataReportWizardStepId.INCLUDE_ALL_SYSTEMS));
                            },
                        }}
                    />
                </Box>
            </Stack>
        </SlideAndFadeInStep>
    );
};
export const IncludeAllSystemsStep: React.FC<MigrateOpsThinDataReportWizardStepProps> = (p) => {
    const wizardState = useMigrateOpsThinDataReportWizardState();
    const { operationJson, setOperationJsonField } = wizardState;
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");
    const includeAllSystems = operationJson.config.migrateops_migration_source_assessment_report.include_all_systems_in_project;
    const setIncludeAllSystems = (includeAllSystems: boolean) => {
        setOperationJsonField(["config", "migrateops_migration_source_assessment_report", "include_all_systems_in_project"], includeAllSystems);
    };

    const clearAllSystemNames = () => {
        setOperationJsonField(["config", "migrateops_migration_source_assessment_report", "include_system_names"], []);
        setOperationJsonField(["config", "migrateops_migration_source_assessment_report", "exclude_system_names"], []);
    };

    const id = ThinDataReportWizardStepId.INCLUDE_ALL_SYSTEMS;

    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Box>
                <Stack textAlign={"center"} direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"} pb={6}>
                    <Typography variant={"h4"}>{"Do you want to include all systems in your assessment?"}</Typography>
                    <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
                        <SelectableCard
                            selected={includeAllSystems === true}
                            onSelect={() => {
                                setIncludeAllSystems(true);
                                clearAllSystemNames();
                            }}
                            cardProps={{ sx: { width: 300 } }}
                            title={"Yes"}
                            description={"Include all systems in the project."}
                        />
                        <SelectableCard
                            selected={includeAllSystems === false}
                            onSelect={() => {
                                setIncludeAllSystems(false);
                            }}
                            cardProps={{ sx: { width: 300 } }}
                            title={"No"}
                            description={"Manually select systems to include."}
                        />
                    </Stack>
                </Stack>
                <StepperNavButtons
                    backButtonProps={{
                        onClick: () => {
                            setSlideDirection("up");
                            p.setCurrentStep(stepConfigs.findIndex((s) => s.id === ThinDataReportWizardStepId.GET_STARTED));
                        },
                    }}
                    nextButtonProps={{
                        disabled: operationJson.config.migrateops_migration_source_assessment_report.include_all_systems_in_project === undefined,
                        onClick: () => {
                            setSlideDirection("down");
                            if (includeAllSystems) {
                                p.setCurrentStep(stepConfigs.findIndex((s) => s.id === ThinDataReportWizardStepId.EXCLUDE_BOOT_VOLUMES));
                            } else {
                                p.setCurrentStep(stepConfigs.findIndex((s) => s.id === ThinDataReportWizardStepId.SELECT_SYSTEMS));
                            }
                        },
                    }}
                />
                <Stack direction={"row"} justifyContent={"center"} pt={2}>
                    <ViewCurrentYamlButton operationJson={operationJson} />
                </Stack>
            </Box>
        </SlideAndFadeInStep>
    );
};

// ======================
// SelectSystemsStep
// ======================

export const SelectSystemsStep: React.FC<MigrateOpsThinDataReportWizardStepProps> = (p) => {
    const wizardState = useMigrateOpsThinDataReportWizardState();
    const { operationJson } = wizardState;

    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");

    const id = ThinDataReportWizardStepId.SELECT_SYSTEMS;
    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Box>
                <Stack textAlign={"center"} pb={6} direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"}>
                    <Typography variant={"h4"}>{"Select the systems you want to include."}</Typography>
                    <SelectSystemsTable />
                    <Box textAlign={"left"} width={"100%"}>
                        <Alert severity={"info"}>
                            <AlertTitle>{" Excluding Systems"}</AlertTitle>
                            {`If you would like to manually exclude systems instead, you can skip this step and edit the generated yaml at the end by referring to the MigrateOps Reference.`}
                        </Alert>
                    </Box>
                    <Box textAlign={"left"} width={"100%"}>
                        <Alert severity={"info"}>
                            <AlertTitle>{"Advanced System Settings"}</AlertTitle>
                            {
                                "If you would like to configure advanced settings for each system, you can refer to the MigrateOps Reference to see how to do so by editing the final generated YAML."
                            }
                        </Alert>
                    </Box>
                </Stack>
                <StepperNavButtons
                    backButtonProps={{
                        onClick: () => {
                            setSlideDirection("up");
                            p.setCurrentStep(stepConfigs.findIndex((s) => s.id === ThinDataReportWizardStepId.INCLUDE_ALL_SYSTEMS));
                        },
                    }}
                    nextButtonProps={{
                        onClick: () => {
                            setSlideDirection("down");
                            p.setCurrentStep(stepConfigs.findIndex((s) => s.id === ThinDataReportWizardStepId.EXCLUDE_BOOT_VOLUMES));
                        },
                    }}
                />
                <Stack direction={"row"} justifyContent={"center"} pt={2}>
                    <ViewCurrentYamlButton operationJson={operationJson} />
                </Stack>
            </Box>
        </SlideAndFadeInStep>
    );
};

// ======================
// SelectSystemsTable
// ======================

interface SelectSystemsTableProps {
    includeSystems?: boolean;
}

export const SelectSystemsTable: React.FC<SelectSystemsTableProps> = (p) => {
    const wizardState = useMigrateOpsThinDataReportWizardState();
    const { operationJson, setOperationJsonField, includeSystems, setIncludeSystems } = wizardState;
    const includedSystems = operationJson.config.migrateops_migration_source_assessment_report.include_system_names;
    const excludedSystems = operationJson.config.migrateops_migration_source_assessment_report.exclude_system_names;
    const toggleSystemToInclude = (name: string) => {
        if (includeSystems) {
            setOperationJsonField(
                ["config", "migrateops_migration_source_assessment_report", "include_system_names"],
                includedSystems.includes(name) ? includedSystems.filter((n) => n !== name) : [...includedSystems, name]
            );
        } else {
            setOperationJsonField(
                ["config", "migrateops_migration_source_assessment_report", "exclude_system_names"],
                excludedSystems.includes(name) ? excludedSystems.filter((n) => n !== name) : [...excludedSystems, name]
            );
        }
    };

    const isSystemAdded = (name: string) => {
        return includeSystems ? includedSystems.includes(name) : excludedSystems.includes(name);
    };

    const projectId = useCurrentProjectID();
    const progressStyle = useLinearProgressStyle(true);

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });

    const onlineSystems = useListGalaxyMigrateDeployments(projectId, true, pageIndex, pageSize);

    const columnHelper = createColumnHelper<GalaxyMigrateDeploymentInfo.AsObject>();

    const cols = [
        columnHelper.display({
            id: "select",
            cell: (props) => {
                const disabled = includeSystems === undefined;
                const selected = isSystemAdded(props.row.original.deployment.systemName);
                const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    toggleSystemToInclude(props.row.original.deployment.systemName);
                };
                return <Checkbox disabled={disabled} color={"secondary"} checked={selected} onChange={onChange} />;
            },
        }),
        columnHelper.accessor((c) => c, {
            id: "host",
            header: "Host",
            cell: (props) => {
                const deploymentInfo = props.row.original;
                return <HostInfoDisplay deploymentInfo={deploymentInfo} />;
            },
        }),
        columnHelper.accessor((c) => c, {
            id: "resource",
            header: "Resource Usage",
            meta: {
                style: {
                    textAlign: "center",
                },
            },
            cell: (props) => {
                const deploymentInfo = props.row.original;
                const hasCpuPercentage = !!deploymentInfo.deployment.systemUptime?.seconds;

                const memoryUsagePercentage = (deploymentInfo.memoryUsed / deploymentInfo.memory) * 100;
                return (
                    <Stack
                        minWidth={250}
                        alignItems={"flexStart"}
                        sx={{
                            opacity: deploymentInfo.deployment.connected ? 1 : 0.3,
                        }}
                    >
                        {hasCpuPercentage ? (
                            <BlackTooltip
                                title={
                                    <Box>
                                        <Typography variant={"caption"} fontWeight={600}>
                                            {deploymentInfo.cpu}
                                        </Typography>
                                        <br />
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {`CPU Model`}
                                        </Typography>
                                    </Box>
                                }
                            >
                                <Stack direction={"row"} spacing={1} alignItems={"flex-end"}>
                                    <Typography variant={"caption"}>{"CPU "}&nbsp;</Typography>
                                    <Stack>
                                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                                            <Typography variant={"caption"}>
                                                {parseCpuCountsFromCpuString(deploymentInfo.cpu).cores} {"Cores"}
                                            </Typography>
                                            <Typography variant={"caption"} color={`${getResourceUsageColor(deploymentInfo.cpuUsagePercentage)}.main`}>
                                                {formatKnownDataType(deploymentInfo.cpuUsagePercentage, KnownDataType.PERCENT)}
                                            </Typography>
                                        </Stack>
                                        <LinearProgress
                                            sx={{
                                                ...progressStyle,
                                                minWidth: 200,
                                            }}
                                            variant={"determinate"}
                                            value={deploymentInfo.cpuUsagePercentage}
                                            color={getResourceUsageColor(deploymentInfo.cpuUsagePercentage)}
                                        />
                                    </Stack>
                                </Stack>
                            </BlackTooltip>
                        ) : null}
                        {deploymentInfo.memoryUsed >= 0 && (
                            <Box pt={1}>
                                <Stack alignItems={"flex-start"} direction={"row"} spacing={1}>
                                    <Typography variant={"caption"}>{"Mem."}</Typography>
                                    <Stack>
                                        <LinearProgress
                                            sx={{
                                                ...progressStyle,
                                                minWidth: 200,
                                            }}
                                            variant={"determinate"}
                                            value={memoryUsagePercentage}
                                            color={getResourceUsageColor(memoryUsagePercentage)}
                                        />
                                        <Stack direction={"row"} justifyContent={"space-between"}>
                                            <Typography variant={"caption"}>
                                                {`${formatKnownDataType(deploymentInfo.memoryUsed, KnownDataType.CAPACITY)}/${formatKnownDataType(
                                                    deploymentInfo.memory,
                                                    KnownDataType.CAPACITY
                                                )}`}
                                            </Typography>
                                            <Typography variant={"caption"} color={`${getResourceUsageColor(memoryUsagePercentage)}.main`}>
                                                {formatKnownDataType(memoryUsagePercentage, KnownDataType.PERCENT)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Box>
                        )}
                    </Stack>
                );
            },
        }),
    ];

    return (
        <Box pt={0} width={"100%"}>
            <QueryTable
                data={onlineSystems.data?.itemsList}
                columns={cols}
                emptyTableConfig={{}}
                error={onlineSystems.error}
                isLoading={onlineSystems.isLoading}
                pageCount={onlineSystems.data?.pagerMeta?.totalPages || 0}
                pagination={{ pageIndex, pageSize }}
                setPagination={setPagination}
                refetch={onlineSystems.refetch}
                queryKey={DeploymentQueryKeys.listGmDeployments}
            />
        </Box>
    );
};

// ======================
// ExcludeBootVolumesStep
// ======================

export const ExcludeBootVolumesStep: React.FC<MigrateOpsThinDataReportWizardStepProps> = (p) => {
    const wizardState = useMigrateOpsThinDataReportWizardState();
    const { operationJson, setOperationJsonField } = wizardState;
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");

    const excludeBootVolumes = operationJson.config.migrateops_migration_source_assessment_report.exclude_boot_volumes;
    const setExcludeBootVolumes = (excludeBootVolumes: boolean) => {
        setOperationJsonField(["config", "migrateops_migration_source_assessment_report", "exclude_boot_volumes"], excludeBootVolumes);
    };
    const id = ThinDataReportWizardStepId.EXCLUDE_BOOT_VOLUMES;

    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Box>
                <Stack textAlign={"center"} pb={6} direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"}>
                    <Typography variant={"h4"}>{"Do you want to include boot volumes in the assessment?"}</Typography>
                    <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
                        <SelectableCard
                            selected={excludeBootVolumes === false}
                            title={"Include"}
                            onSelect={() => setExcludeBootVolumes(false)}
                            description={"Include all boot volumes in the report."}
                        />
                        <SelectableCard
                            selected={excludeBootVolumes === true}
                            onSelect={() => setExcludeBootVolumes(true)}
                            title={"Exclude"}
                            description={"Exclude all boot volumes from the report."}
                        />
                    </Stack>
                </Stack>
                <StepperNavButtons
                    backButtonProps={{
                        onClick: () => {
                            setSlideDirection("up");
                            if (wizardState.operationJson.config.migrateops_migration_source_assessment_report.include_all_systems_in_project === false) {
                                p.setCurrentStep(stepConfigs.findIndex((s) => s.id === ThinDataReportWizardStepId.SELECT_SYSTEMS));
                            } else {
                                p.setCurrentStep(stepConfigs.findIndex((s) => s.id === ThinDataReportWizardStepId.INCLUDE_ALL_SYSTEMS));
                            }
                        },
                    }}
                    nextButtonProps={{
                        disabled: excludeBootVolumes === undefined,
                        onClick: () => {
                            setSlideDirection("down");
                            p.setCurrentStep(stepConfigs.findIndex((s) => s.id === ThinDataReportWizardStepId.DEFAULT_QOS_LEVEL));
                        },
                    }}
                />
                <Stack direction={"row"} justifyContent={"center"} pt={2}>
                    <ViewCurrentYamlButton operationJson={operationJson} />
                </Stack>
            </Box>
        </SlideAndFadeInStep>
    );
};

// ======================
// DefaultQosLevelStep
// ======================

export const DefaultQosLevelStep: React.FC<MigrateOpsThinDataReportWizardStepProps> = (p) => {
    const { currentStep, setCurrentStep } = p;
    const wizardState = useMigrateOpsThinDataReportWizardState();
    const { operationJson, setOperationJsonField } = wizardState;
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");
    const containerRef = useRef();

    const defaultQosLevel = operationJson.config.migrateops_migration_source_assessment_report.default_qos_level;
    const setDefaultQosLevel = (defaultQosLevel: string) => {
        setOperationJsonField(["config", "migrateops_migration_source_assessment_report", "default_qos_level"], defaultQosLevel);
    };

    const id = ThinDataReportWizardStepId.DEFAULT_QOS_LEVEL;

    const qosLevels = [
        {
            title: getSyncQosImpactLevelLabel(SyncQos.ImpactLevel.MINIMUM),
            description: "Application will have absolute priority",
            icon: <img src={MinimumMode} height={72} alt={"Minimum Mode"} />,
            value: `MINIMUM`,
        },
        {
            title: getSyncQosImpactLevelLabel(SyncQos.ImpactLevel.MODERATE),
            description: "Migration will compete with Application for up to 30%",
            icon: <img src={ModerateMode} height={72} alt={"Moderate Mode"} />,
            value: `MODERATE`,
        },
        {
            title: getSyncQosImpactLevelLabel(SyncQos.ImpactLevel.AGGRESSIVE),
            description: "Migration will compete with Application for up to 75%",
            icon: <img src={AggressiveMode} height={72} alt={"Aggressive Mode"} />,
            value: `AGGRESSIVE`,
        },
        {
            title: getSyncQosImpactLevelLabel(SyncQos.ImpactLevel.RELENTLESS),
            description: "Migrate as fast as possible",
            icon: <img src={RelentlessMode} height={72} alt={"Relentless Mode"} />,
            value: `RELENTLESS`,
        },
    ];

    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Box>
                <Stack textAlign={"center"} pb={6} direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"}>
                    <Typography variant={"h4"}>{"Select a default QOS level for the assessment."}</Typography>
                    <Grid container spacing={2} p={2}>
                        {qosLevels.map((qosLevel, index) => {
                            return (
                                <Grid item xs={12} sm={6} md={3}>
                                    <SelectableCard
                                        cardProps={{ sx: { height: "100%" } }}
                                        key={index}
                                        selected={defaultQosLevel === qosLevel.value}
                                        onSelect={() => setDefaultQosLevel(qosLevel.value)}
                                        title={qosLevel.title}
                                        description={qosLevel.description}
                                        icon={qosLevel.icon}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Stack>
                <StepperNavButtons
                    backButtonProps={{
                        onClick: () => {
                            setSlideDirection("up");
                            setCurrentStep(stepConfigs.findIndex((s) => s.id === ThinDataReportWizardStepId.EXCLUDE_BOOT_VOLUMES));
                        },
                    }}
                    nextButtonProps={{
                        disabled: defaultQosLevel === undefined,
                        onClick: () => {
                            setCurrentStep(stepConfigs.findIndex((s) => s.id === ThinDataReportWizardStepId.FINISH_UP));
                            setSlideDirection("down");
                        },
                    }}
                />
                <Stack direction={"row"} justifyContent={"center"} pt={2}>
                    <ViewCurrentYamlButton operationJson={operationJson} />
                </Stack>
            </Box>
        </SlideAndFadeInStep>
    );
};

// ======================
// FinishUpStep
// ======================

export const FinishUpStep: React.FC<MigrateOpsThinDataReportWizardStepProps> = (p) => {
    const { setCurrentStep } = p;
    const projectId = useCurrentProjectID();
    const wizardState = useMigrateOpsThinDataReportWizardState();
    const { operationJson, resetState } = wizardState;
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");
    const id = ThinDataReportWizardStepId.FINISH_UP;
    const clonedJson = {
        operations: [JSON.parse(JSON.stringify(operationJson))],
    };

    const sanitizedJson = sanitizeObjectOfEmptyFields(clonedJson, true);

    const generatedYamlId = "generated-yaml-id";

    const [editMode, setEditMode] = useState<boolean>(false);
    const [editorValue, setEditorValue] = useState<string>("");
    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const goToMigrateOpsDetailPage = useNavigateToOpDetails();
    const createOperation = useCreateNewOperationFromYaml();
    const handleSubmit = async () => {
        const res = await createOperation.mutateAsync(editorValue);
        if (res) {
            goToMigrateOpsDetailPage(res.operationIdsList[0]);
        }
    };

    useEffect(() => {
        setEditorValue(
            YAML.stringify(sanitizedJson, {
                indent: 2,
                defaultKeyType: "PLAIN",
                defaultStringType: "QUOTE_DOUBLE",
            })
        );
    }, [sanitizedJson]);

    const openMigrateOpsReference = () => {
        const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATE_OPS_DOCS), { projectId });
        window.open(p, "_blank");
    };

    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Stack direction={"column"} spacing={2}>
                <Stack textAlign={"center"} direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"}>
                    <Typography variant={"h4"}>{"Your configuration has been generated."}</Typography>
                    <Typography variant={"body1"}>
                        {`Below is the generated configuration. You can now choose to make any edits to the configuration or go ahead and create the assessment.`}
                    </Typography>
                </Stack>
                {editMode ? (
                    <>
                        <Box pt={2}>
                            <CodeCard sx={{ padding: 2, maxHeight: "55vh", overflow: "auto" }}>
                                <Stack direction={"row"} justifyContent={"flex-end"}>
                                    <Button variant={"outlined"} color={"neutral"} onClick={openMigrateOpsReference}>
                                        {"Open MigrateOps Reference"}
                                    </Button>
                                </Stack>
                                <SimpleCodeEditor value={editorValue} setValue={setEditorValue} />
                            </CodeCard>
                        </Box>
                    </>
                ) : (
                    <CodeCard sx={{ maxHeight: "calc(100vh - 250px)", overflow: "auto" }}>
                        <CardContent sx={{ height: "100%", overflow: "auto" }}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Typography variant={"h6"}>{"Configuration"}</Typography>
                                <Button variant={"outlined"} color={"neutral"} onClick={toggleEditMode}>
                                    {"Edit Config"}
                                </Button>
                            </Box>
                            <Box>
                                <ClipboardText clipboardId={generatedYamlId}>
                                    <pre>
                                        {YAML.stringify(sanitizedJson, {
                                            indent: 2,
                                            defaultKeyType: "PLAIN",
                                            defaultStringType: "QUOTE_DOUBLE",
                                        })}
                                    </pre>
                                </ClipboardText>
                                <Stack direction={"row"} spacing={1}>
                                    <ClipboardButton color={"neutral"} clipboardId={generatedYamlId} label={"Copy Config"} />
                                </Stack>
                            </Box>
                        </CardContent>
                    </CodeCard>
                )}
                <Stack direction={"row"} spacing={1}>
                    <StepperNavButtons
                        backButtonProps={{
                            onClick: () => {
                                setSlideDirection("up");
                                setCurrentStep(stepConfigs.findIndex((s) => s.id === ThinDataReportWizardStepId.DEFAULT_QOS_LEVEL));
                            },
                        }}
                        nextButtonProps={{
                            disabled: false,
                            label: "Create Assessment",
                            onClick: () => {
                                handleSubmit();
                                resetState();
                            },
                        }}
                    />
                </Stack>
            </Stack>
        </SlideAndFadeInStep>
    );
};
