import { GeneratedProtobufJsonType, ProtobufEnumType, ProtobufMessageFieldType, ProtobufMessageType } from "./GeneratedProtobufJsonTypes";
import * as YAML from "yaml";
import parse, { DOMNode, domToReact, Element, HTMLReactParserOptions } from "html-react-parser";
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Tooltip } from "@mui/material";
import React from "react";

const getIsCustomFieldType = (field: ProtobufMessageFieldType) => {
    if (field.type === field.fullType && field.type === field.longType) {
        return false;
    }
    if (field.fullType.includes("google.protobuf")) {
        return false;
    }
    return true;
};
//takes the definition JSON object from the API and returns an object with all refs replaced with their actual definitions
const dereferenceDefinition = (fullProtoJson: GeneratedProtobufJsonType, currentConfig: ProtobufMessageType) => {
    let obj = currentConfig;
    if (obj.hasFields) {
        obj.fields.forEach((field, index) => {
            if (getIsCustomFieldType(field)) {
                const dereferencedType = fullProtoJson.files
                    .find((file) => file.messages.find((message) => message.fullName === field.fullType))
                    ?.messages.find((message) => message.fullName === field.fullType);
                if (!!dereferencedType) {
                    field.dereferencedType = dereferencedType;
                    if (dereferencedType?.hasFields) {
                        field.dereferencedType = dereferenceDefinition(fullProtoJson, dereferencedType);
                    }
                }
                const dereferencedEnum = fullProtoJson.files
                    .find((file) => file.enums.find((enumType) => enumType.fullName === field.fullType))
                    ?.enums.find((enumType) => enumType.fullName === field.fullType);
                if (!!dereferencedEnum) {
                    field.dereferencedEnum = dereferencedEnum;
                }
            }
        });
    }
    return obj;
};

export interface ConfigFieldMap {
    [key: string]: ConfigField;
}

export interface ConfigField {
    dataType: "oneOf" | "string" | "timestamp" | "duration" | "integer" | "boolean" | "object" | "array" | "null" | "undefined";
    title?: string;
    description: string;
    required?: boolean;
    properties?: ConfigFieldMap;
    definitionName?: string;
    arrayType?: string;
    enum?: ProtobufEnumType;
    arrayItemType?: ConfigFieldMap;
    arrayItemDefinitionName?: string;
    objectValueType?: string;
    isOneOf?: boolean;
    oneOfType?: string;
    oneOfFields?: ConfigFieldMap;
}

const convertProtoTypeToConfigType = (field: ProtobufMessageFieldType): ConfigField["dataType"] => {
    if (field.label === "repeated") {
        return "array";
    }
    if (field.type === "string") {
        return "string";
    }
    if (field.type === "int32" || field.type === "int64" || field.type === "uint32" || field.type === "uint64") {
        return "integer";
    }
    if (field.type === "bool") {
        return "boolean";
    }
    if (!!field.dereferencedType) {
        return "object";
    }
    if (!!field.dereferencedEnum) {
        return "string";
    }
    if (field.type === "Duration") {
        return "duration";
    }
    if (field.type === "Timestamp") {
        return "timestamp";
    }
    return "undefined";
};
//takes the dereferenced definition object and returns a custom map of all the fields with extra information
const getSchemaConfigFieldMap = (schema: ProtobufMessageType): ConfigFieldMap => {
    const configFieldMap: ConfigFieldMap = {};
    for (let field of schema.fields) {
        if (field.isoneof) {
            if (!configFieldMap[field.oneofdecl]) {
                configFieldMap[field.oneofdecl] = {
                    dataType: "oneOf",
                    description: "",
                    oneOfFields: {},
                };
            }
            configFieldMap[field.oneofdecl].oneOfFields[field.name] = {
                description: field.description,
                dataType: convertProtoTypeToConfigType(field),
                isOneOf: field.isoneof,
                oneOfType: field.oneofdecl,
            };
            //if the property is an array, set the array type. if the array type is an object, set the properties
            if (configFieldMap[field.oneofdecl].oneOfFields[field.name].dataType === "array") {
                if (getIsCustomFieldType(field)) {
                    configFieldMap[field.oneofdecl].oneOfFields[field.name].arrayItemDefinitionName = field.type;
                    configFieldMap[field.oneofdecl].oneOfFields[field.name].arrayType = "object";
                    configFieldMap[field.oneofdecl].oneOfFields[field.name].arrayItemType = getSchemaConfigFieldMap(field.dereferencedType);
                } else {
                    configFieldMap[field.oneofdecl].oneOfFields[field.name].arrayType = field.type;
                }
            }
            //recurse into properties of object fields
            if (configFieldMap[field.oneofdecl].oneOfFields[field.name].dataType === "object") {
                configFieldMap[field.oneofdecl].oneOfFields[field.name].definitionName = field.type;
                configFieldMap[field.oneofdecl].oneOfFields[field.name].properties = getSchemaConfigFieldMap(field.dereferencedType);
            }
            //set the enum
            if (!!field.dereferencedEnum) {
                configFieldMap[field.oneofdecl].oneOfFields[field.name].enum = field.dereferencedEnum;
            }
        }
        if (!field.isoneof) {
            configFieldMap[field.name] = {
                description: field.description,
                dataType: convertProtoTypeToConfigType(field),
                isOneOf: field.isoneof,
                oneOfType: field.oneofdecl,
            };
            //if the property is an array, set the array type. if the array type is an object, set the properties
            if (configFieldMap[field.name].dataType === "array") {
                if (getIsCustomFieldType(field)) {
                    configFieldMap[field.name].arrayItemDefinitionName = field.type;
                    configFieldMap[field.name].arrayType = "object";
                    configFieldMap[field.name].arrayItemType = getSchemaConfigFieldMap(field.dereferencedType);
                } else {
                    configFieldMap[field.name].arrayType = field.type;
                }
            }
            //recurse into properties of object fields
            if (configFieldMap[field.name].dataType === "object") {
                configFieldMap[field.name].definitionName = field.type;
                configFieldMap[field.name].properties = getSchemaConfigFieldMap(field.dereferencedType);
            }
            //set the enum
            if (!!field.dereferencedEnum) {
                configFieldMap[field.name].enum = field.dereferencedEnum;
            }
        }
    }

    return configFieldMap;
};
export const getYamlPlaceholderValueFromDataType = (dataType: ConfigField["dataType"]): any => {
    if (dataType === "string") {
        return "";
    }
    if (dataType === "integer") {
        return 0;
    }
    if (dataType === "boolean") {
        return false;
    }
    if (dataType === "timestamp") {
        return "yyyy-mm-ddThh:mm:ssZ";
    }
    if (dataType === "duration") {
        return "1s";
    }
};
//creates the operation config field map for the currently selected recipe
export const getCurrentRecipeConfigFieldMap = (fullProtoJson: GeneratedProtobufJsonType, currentConfig: ProtobufMessageType, recipeId: string) => {
    const dereferencedConfig = dereferenceDefinition(fullProtoJson, currentConfig);
    const configFieldMap = getSchemaConfigFieldMap(dereferencedConfig);
    const newConfigFieldMap = { ...configFieldMap };
    newConfigFieldMap[recipeId.toLowerCase()] = newConfigFieldMap["recipe"].oneOfFields[recipeId.toLowerCase()];
    newConfigFieldMap[recipeId.toLowerCase()].isOneOf = false;
    delete newConfigFieldMap["recipe"];
    return newConfigFieldMap;
};

//takes dereferenced definition object and creates an object with all the fields and their placeholder values
export const getOperationConfigJson = (fullProtoJson: GeneratedProtobufJsonType, currentConfig: ProtobufMessageType, recipeId: string) => {
    const dereferencedConfig = dereferenceDefinition(fullProtoJson, currentConfig);
    return dereferencedConfig;
};

export const getYamlBuilderFieldFromConfigField = (
    configField: ConfigField,
    fieldName: string,
    value: any,
    onChange: React.ChangeEventHandler
): React.ReactNode => {
    if (configField.dataType === "string") {
        return <TextField value={value} variant={"filled"} fullWidth label={fieldName} onChange={onChange} />;
    }
    if (configField.dataType === "integer") {
        return <TextField type={"number"} value={value} variant={"filled"} fullWidth label={fieldName} onChange={onChange} />;
    }
    if (configField.dataType === "boolean") {
        return (
            <FormControl>
                <RadioGroup row aria-labelledby={`boolean-label`} name={`boolean`} value={value} onChange={onChange}>
                    <FormControlLabel value={"true"} control={<Radio />} label={"true"} />
                    <FormControlLabel value={"false"} control={<Radio />} label={"false"} />
                </RadioGroup>
            </FormControl>
        );
    }
    if (configField.dataType === "timestamp") {
        return (
            <TextField
                helperText={"RFC3339 format, e.g. 2019-10-12T07:20:50.52Z"}
                defaultValue={"yyyy-mm-ddThh:mm:ssZ"}
                value={value}
                variant={"filled"}
                fullWidth
                label={fieldName}
                onChange={onChange}
            />
        );
    }
    if (configField.dataType === "duration") {
        return (
            <TextField
                helperText={"duration format in [s | ms | us], e.g. 30s"}
                defaultValue={"1s"}
                value={value}
                variant={"filled"}
                fullWidth
                label={fieldName}
                onChange={onChange}
            />
        );
    }
    return null;
};

// //creates the yaml string from the config field map
// const formatConfigFieldMapForYaml = (configFieldMap: ConfigFieldMap, extraCharacter?: string): any => {
//     const configJsonObj: { [key: string]: any } = {};
//
//     for (let property in configFieldMap) {
//         let extraChar: string = extraCharacter ?? "";
//         let propertyName = property;
//         //if the property has comments, add special characters around the property name
//         if (!!configFieldMap[property].description || !!configFieldMap[property].enum) {
//             propertyName = `${extraCharacter ?? ""}@@@${property}@@@`;
//         }
//         if (configFieldMap[property].dataType === "object") {
//             if (!!configFieldMap[property].objectValueType) {
//                 configJsonObj[propertyName] = {
//                     keyName: getYamlPlaceholderValueFromDataType(configFieldMap[property].objectValueType),
//                 };
//             } else {
//                 extraChar = `!${extraCharacter ?? ""}`;
//                 configJsonObj[propertyName] = formatConfigFieldMapForYaml(configFieldMap[property].properties, extraChar);
//             }
//         } else if (configFieldMap[property].dataType === "array") {
//             if (configFieldMap[property].arrayType === "object") {
//                 extraChar = `!${extraCharacter ?? ""}`;
//                 configJsonObj[propertyName] = [formatConfigFieldMapForYaml(configFieldMap[property].arrayItemType, extraChar)];
//             } else {
//                 const placeholderValue = getYamlPlaceholderValueFromDataType(configFieldMap[property].arrayType);
//                 configJsonObj[propertyName] = [placeholderValue];
//             }
//         } else {
//             configJsonObj[propertyName] = getYamlPlaceholderValueFromDataType(configFieldMap[property].dataType);
//         }
//     }
//
//     return configJsonObj;
// };
//
// //adds tooltip comments to the yaml string by converting to html
// const formatYamlWithTooltipComments = (configFieldMap: ConfigFieldMap, recipeId: string) => {
//     const configJsonObj = formatConfigFieldMapForYaml(configFieldMap);
//     const fullOperationJsonObject = {
//         operations: [
//             {
//                 name: "",
//                 recipe: recipeId,
//                 notes: "",
//                 config: configJsonObj,
//             },
//         ],
//     };
//
//     let yamlString = YAML.stringify(fullOperationJsonObject)
//         .replaceAll("|", "")
//         .replaceAll(/-\s\n\s\s\s\s/gi, "- ");
//     const regex = /!*@@@([^@]+)@@@/gi;
//
//     const allKeysNeedingComments = [...yamlString.matchAll(regex)].map((match) => match[0]);
//     const commentArray = flattenConfigFieldMapIntoArrayOfComments(configFieldMap);
//
//     for (let i = 0; i < allKeysNeedingComments.length; i++) {
//         let indentation = "";
//         const key = allKeysNeedingComments[i];
//         for (let j = 0; j < key.length; j++) {
//             if (key[j] === "!") {
//                 indentation += " ";
//             }
//         }
//         let strippedKey = key.replace(/!*@@@/gi, "");
//         const comment = commentArray[i];
//         yamlString = yamlString.replace(`"${key}"`, `${strippedKey}(((${comment})))`);
//     }
//
//     //do an initial split based on new line character
//     let yamlStringArrayByLine = yamlString.split(/\n/);
//
//     //if a line contains a multi-line comment, combine it with the next lines until the comment ends
//     for (let i = 0; i < yamlStringArrayByLine.length; i++) {
//         if (yamlStringArrayByLine[i].includes("(((") && !yamlStringArrayByLine[i].includes(")))")) {
//             let j = i + 1;
//             while (!yamlStringArrayByLine[j].includes(")))")) {
//                 yamlStringArrayByLine[i] += yamlStringArrayByLine[j];
//                 yamlStringArrayByLine[j] = "";
//                 j++;
//             }
//             yamlStringArrayByLine[i] += yamlStringArrayByLine[j];
//             yamlStringArrayByLine[j] = "";
//         }
//     }
//     //remove empty lines
//     yamlStringArrayByLine = yamlStringArrayByLine.filter((line: string) => line !== "");
//
//     //transform lines into html elements
//     for (let i = 0; i < yamlStringArrayByLine.length; i++) {
//         if (yamlStringArrayByLine[i].includes("(((")) {
//             yamlStringArrayByLine[i] = `<span class="replace"><p>${yamlStringArrayByLine[i]}</p></span>`;
//         } else {
//             yamlStringArrayByLine[i] = `<span><p>${yamlStringArrayByLine[i]}</p></span>`;
//         }
//     }
//     //join lines back together in one html string
//     yamlString = yamlStringArrayByLine.join("");
//
//     //parse html string into react components, replacing span elements containing comments with tooltips
//     const options: HTMLReactParserOptions = {
//         replace: (domNode: DOMNode) => {
//             if (domNode instanceof Element) {
//                 if (domNode.attribs?.class === "replace") {
//                     const childComponent = domToReact(domNode.children, options);
//                     const childComponentText = (childComponent as JSX.Element).props.children;
//                     const comment = childComponentText.split("(((")[1].split(")))")[0];
//                     const fieldName = childComponentText.split("(((")[0];
//                     const placeholder = childComponentText.split("(((")[1].split(")))")[1];
//                     return (
//                         <Tooltip placement={"top-start"} title={comment}>
//                             <Box>
//                                 <p>
//                                     {fieldName}
//                                     {placeholder}
//                                 </p>
//                             </Box>
//                         </Tooltip>
//                     );
//                 }
//             } else {
//                 return;
//             }
//         },
//     };
//
//     //add the rest of the yaml file
//
//     return parse(yamlString, options);
// };
//
// //creates a flattened map of all the comments in the config field map
// const flattenConfigFieldMapIntoArrayOfComments = (obj: ConfigFieldMap): any[] => {
//     const array: any[] = [];
//     for (let key in obj) {
//         let comment: string = "";
//         if (obj[key].dataType === "object") {
//             if (!!obj[key].description) {
//                 comment = obj[key].description;
//                 array.push(comment);
//             }
//             array.push(...flattenConfigFieldMapIntoArrayOfComments(obj[key].properties));
//         } else if (obj[key].dataType === "array") {
//             if (!!obj[key].description) {
//                 comment = obj[key].description;
//                 array.push(comment);
//             }
//             if (obj[key].arrayType === "object") {
//                 array.push(...flattenConfigFieldMapIntoArrayOfComments(obj[key].arrayItemType));
//             }
//         } else if (!!obj[key].description) {
//             comment = obj[key].description;
//             if (!!obj[key].enum) {
//                 comment = `${comment}\n#${Object.values(obj[key].enum).join(", ")}`;
//             }
//             array.push(comment);
//         } else if (!!obj[key].enum) {
//             comment = `${obj[key].title}: ${Object.values(obj[key].enum).join(", ")}`;
//             array.push(comment);
//         }
//     }
//     return array;
// };
